<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3 orgDetailsContainer">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Conference Organiser View
            </div>
            <div
              class="
                d-flex
                flex-row
                align-center
                justify-start
                subtitle-1
                font-weight-light
              "
            >
              <span>This shows the Conference Organiser profile</span>
            </div>
          </template>
          <div>
            <v-overlay
              :value="overlay"
              opacity="1"
              color="white"
              z-index="0"
              absolute="absolute"
            >
              <v-progress-circular indeterminate size="100" color="primary">
              </v-progress-circular>
            </v-overlay>
          </div>
          <v-card-text>
            <v-row
              class="d-flex flex-row align-start justify-space-between"
              v-if="details"
            >
              <v-col cols="12" class="px-0">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">Organisation Details</h3>
                  </v-col>
                </v-row>
                <!-- individual row for organiser details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Organisation Name:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.user.organisation"
                    >{{ details.user.organisation }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <!-- v-if="details.profile && details.profile.website != undefined" -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Organisation Website:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="details.profile && details.profile.website"
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="validateUrl(details.profile.website)"
                            target="_blank"
                          >{{ validateUrl(details.profile.website) }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Visit
                        <b>
                          <i>{{ validateUrl(details.profile.website) }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Organisation Address:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      v-if="details.profile && details.profile.address"
                      class="subtitle-1 text-left"
                    >{{
                        `${
                          details.profile.address.street
                            ? details.profile.address.street
                            : ""
                        } ${
                          details.profile.address.postalCode
                            ? `[${details.profile.address.postalCode}]`
                            : ""
                        } ${
                          details.profile.address.city
                            ? `, ${details.profile.address.city}, `
                            : ""
                        }${
                          details.profile.address.country
                            ? `${details.profile.address.country}`
                            : ""
                        }`
                      }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Organisation Type:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile.organisationType"
                    >{{ details.profile.organisationType }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">No. of Members:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile.noOfMembers"
                    >{{ details.profile.noOfMembers }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >No. of Annual Conferences:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile.noConfOrgAnnual"
                    >{{ details.profile.noConfOrgAnnual }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Avg. No. of Delegates (per conference):</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.profile.avgNoOfDelePerConf"
                    >{{ details.profile.avgNoOfDelePerConf }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row
                  class="px-0"
                  v-if="
                    details.profile &&
                    details.profile.topics.length > 0 &&
                    details.profile.topics[0]
                  "
                >
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Main Conference Topic:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      class="d-flex flex-row flex-wrap mx-0"
                      v-if="
                        details.profile.topics &&
                        details.profile.topics[0].topicName
                      "
                    >
                      <v-chip class="mr-2 grey--text" label>{{
                          details.profile.topics[0].topicName
                        }}
                      </v-chip>
                    </v-row>
                    <v-row class="mx-0" v-else>
                      <span
                        class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        "
                      >n/a</span
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row
                  class="px-0"
                  v-if="
                    details.profile &&
                    details.profile.topics.length > 0 &&
                    details.profile.topics[0]
                  "
                >
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Subtopics covered at conference:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      class="d-flex flex-row flex-wrap mx-0"
                      v-if="
                        details.profile.topics[0].subTopics &&
                        details.profile.topics[0].subTopics.length > 1
                      "
                    >
                      <v-chip
                        class="mr-2 mb-2 grey--text"
                        label
                        :key="index"
                        v-for="(subTopics, index) in details.profile.topics[0]
                          .subTopics"
                      >{{ subTopics }}
                      </v-chip
                      >
                    </v-row>
                    <v-row class="mx-0" v-else>
                      <span
                        class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        "
                      >n/a</span
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Mission of Organisation:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <p
                      class="body-1 text-left"
                      v-if="details.profile.missionOfOrganisation"
                    >
                      {{ details.profile.missionOfOrganisation }}
                    </p>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left"
                    >Social Media Channels:</span
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      class="d-flex flex-row flex-wrap mx-0"
                      v-if="details.profile.socialMedia.length > 0"
                    >
                      <v-col
                        v-for="socialMedia in details.profile.socialMedia"
                        :key="socialMedia._id"
                        v-show="socialMedia.link"
                        class="py-0 pl-0 flex-grow-0"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-on="on"
                              class="mr-2 mb-2"
                              label
                              :href="validateUrl(socialMedia.link)"
                              target="_blank"
                              :color="getSocialColor(socialMedia.name)"
                            >
                              <font-awesome-icon
                                :icon="['fab', socialMedia.icon]"
                                class="mr-2"
                                size="2x"
                                color="white"
                              />
                              <span
                                class="
                                  text-left
                                  caption
                                  text-capitalize
                                  white--text
                                "
                              >{{ socialMedia.name }}</span
                              >
                            </v-chip>
                          </template>
                          <span>{{ validateUrl(socialMedia.link) }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex flex-row mx-0" v-else>
                      <span
                        class="
                          subtitle-1
                          text-left
                          platinum--text
                          text--darken-3
                        "
                      >n/a</span
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
              </v-col>
              <v-col cols="12" class="px-0" v-if="details.profile">
                <v-row class="px-0">
                  <v-col cols="12">
                    <h3 class="headline text-left">
                      Organiser (Representative) Details
                    </h3>
                  </v-col>
                </v-row>
                <!-- individual row for organiser details -->
                <v-row class="px-0">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Name:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="details.user.name"
                    >{{ details.user.name }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Email:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom v-if="details.user.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${details.user.email}`">{{
                              details.user.email
                            }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ details.user.name }}</b> via
                        <b>
                          <i>{{ details.user.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Phone:</span>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip
                      bottom
                      v-if="
                        details.profile.telephoneNo &&
                        details.profile.telephoneNo.no
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a
                            :href="`tel:+${
                              details.profile.telephoneNo.code +
                              details.profile.telephoneNo.no
                            }`"
                          >{{
                              `+${details.profile.telephoneNo.code} ${details.profile.telephoneNo.no}`
                            }}</a
                          >
                        </span>
                      </template>
                      <span>
                        Call
                        <b>{{ details.user.name }}</b> via
                        <b>
                          <i>{{
                              `+${details.profile.telephoneNo.code} ${details.profile.telephoneNo.no}`
                            }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
                <!-- individual row for organiser details -->
                <v-row class="px-0" v-if="details.profile">
                  <v-col cols="4">
                    <span class="subtitle-1 text-left">Joined Date:</span>
                  </v-col>
                  <v-col cols="8">
                    <span
                      class="subtitle-1 text-left"
                      v-if="computedDateFormat"
                    >{{ computedDateFormat }}</span
                    >
                    <span
                      class="subtitle-1 text-left platinum--text text--darken-3"
                      v-else
                    >n/a</span
                    >
                  </v-col>
                </v-row>
                <!-- end of individual row for organiser details -->
              </v-col>
              <v-col cols="12" class="px-0" v-if="details.profile">
                <v-row class="px-0">
                  <v-col cols="5">
                    <h3 class="headline text-left">
                      Update Representative Account
                    </h3>
                    <v-divider></v-divider>
                    <v-row class="px-0">
                      <v-col cols="10" style="margin-top: 20px">
                        <v-text-field
                          style="margin-bottom: -40px"
                          type="text"
                          label="Organizer Name"
                          outlined
                          v-model="name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          style="margin-bottom: -40px"
                          type="text"
                          label="Email"
                          outlined
                          v-model="email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          style="margin-bottom: -40px"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'"
                          v-model="password"
                          label="New Password"
                          outlined
                          @click:append="show = !show"
                          :error-messages="errorPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-btn block style="background-color: #35a7ff;" @click="dialog = true">
                          <v-icon left dark>
                            mdi-card-account-details
                          </v-icon>
                          Update Representative Account
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card height="45vh" style="overflow-y: scroll;padding-right: 10px;padding-left: 10px;padding-top: 20px">
        <v-card-title class="px-2 px-sm-6">
          <v-row class="pb-3">
            <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
              <h2 class="headline text-left">Please confirm</h2>
            </v-col>
            <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
              <v-btn class="dialog-close-btn" icon color="grey lighten-1" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="details.user.email !== email || details.user.name !== name || password !== ''">
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize red--text">Current</span>
              <br/>
              <span class="text-left caption text-capitalize black--text"
                    v-if="details.user.name !== name">Name :- {{ details.user.name }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="details.user.email !== email">Email :- {{
                  details.user.email.toLowerCase()
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text"
                    v-if="password !== ''">Password :- hidden</span>
            </v-col>
            <v-col cols="6" md="6">
              <span class="text-left caption text-capitalize green--text">New</span>
              <br/>
              <span class="text-left caption text-capitalize black--text"
                    v-if="details.user.name !== name">Name :- {{ name }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="details.user.email !== email">Email :- {{
                  email.toLowerCase()
                }}</span>
              <v-divider></v-divider>
              <span class="text-left caption text-capitalize black--text" v-if="password !== ''">Password :- {{
                  password === '' ? 'Not Changed!' : password
                }}</span>
            </v-col>
          </v-row>
          <v-row v-else style="text-align: center">
            <span style="text-align: center;width: 100%;margin-top: 10vh"
                  class="text-center caption text-capitalize grey--text font-weight-bold">There is no changes to update!</span>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6"
                        v-if="details.user.email !== email || details.user.name !== name || password !== ''">
          <v-row
            class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-start px-0">
              <v-btn class="px-8" color="secondary" @click="dialog = false" tile outlined depressed bottom>
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-end px-0"
                   v-if="details.user.email !== email || details.user.name !== name || password !== ''">
              <v-btn class="px-8 mx-0" color="secondary" tile depressed bottom right @click="updateProfile">
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Apicalls from "../../../services/login.service";
import OrgApi from "../../../services/organiser.service";
import {mapGetters} from "vuex";

export default {
  name: "OrganiserView",
  components: {},
  data() {
    return {
      dialog: false,
      details: null,
      isApiload: false,
      overlay: true,
      show: false,
      errorPassword: null,
      password: null,
      email: null,
      name: null,
      staticErrorMessages: {
        email: {
          isEmpty: "Email Address cannot be empty"
        },
        password: {
          isEmpty: "Password cannot be empty"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      email: "getUserEmail",
    }),
    computedDateFormat() {
      return this.details.user.created_at.split("T")[0];
    },
  },
  mounted() {
    this.overlay = true;

    let payload = {
      email: localStorage.getItem("setUserEmail"),
    };
    this.isApiload = false;
    Apicalls.getDetails(payload)
      .then((res) => {
        // console.log(res.data);
        this.details = res.data.content;
        this.isApiload = true;
        this.overlay = false;
        this.name = this.details.user.name;
        this.email = this.details.user.email;
        this.password = '';
      })
      .catch();
  },
  methods: {
    getSocialColor(name) {
      if (name == "LinkedIn") {
        return "#0a66c2";
      } else if (name == "Twitter") {
        return "#1da1f2";
      } else if (name == "Youtube") {
        return "#ff0000";
      } else if (name == "Instagram") {
        return "#f00075";
      } else if (name == "Vimeo") {
        return "#00adef";
      } else if (name == "Flickr") {
        return "#ff0084";
      } else {
        return "secondary";
      }
    },
    validateUrl(url) {
      if (!url) return;
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
    updateProfile() {
      var input = document.createElement('input');
      input.type = 'email';
      input.required = true;
      input.value = this.email.toLowerCase();
      if (!/\S+@\S+\.\S+/.test(this.email.toLowerCase())) {
        let payload2 = {
          snackBarText: `Given email address is not valid!`,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload2);
      } else {
        const payload = {
          oldEmail: this.details.user.email,
          newEmail: this.email,
          newName: this.name,
          password: this.password
        }
        OrgApi.updateAccount(payload).then((res) => {
          console.log(res)
          Apicalls.getDetails({email: this.email})
            .then((res) => {
              // console.log(res.data);
              this.details = res.data.content;
              this.isApiload = true;
              this.overlay = false;
              this.name = this.details.user.name;
              this.email = this.details.user.email;
              this.password = '';
            })
            .catch();
          this.dialog = false;
          let payload2 = {
            snackBarText: `Representative Account Updated!`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }).catch(() => {
          let payload2 = {
            snackBarText: `Something went wrong! \nPlease try again.`,
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          this.dialog = false;
        });
      }
    }
  },
};
</script>
<style scoped>
.orgDetailsContainer {
  min-height: 500px;
}

/* Large devices (large tablet to laptop) */
@media (min-width: 960px) and (max-width: 1264px) {
}

/* Medium devices (large tablet to laptop, less than 992px) */
@media (max-width: 960px) {
}
</style>
